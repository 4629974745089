.react-calendar {
    width: 350px;
    min-width: fit-content;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    width: 400px;
    margin: 0 auto;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    color: #012063;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;

  }
  
  .react-calendar__navigation button:nth-child(1)
  , .react-calendar__navigation button:nth-child(2)
  , .react-calendar__navigation button:nth-child(4)
  , .react-calendar__navigation button:nth-child(5) {
    background-color: rgba(1, 32, 99, 0.4);
    color:#012063;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    min-width: 30px;
    margin: 0 4px;
  }

  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
    border: solid 2px #012063;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 24px 0;
    color: white;
    background: #012063e6;
    border-radius: 7px;
    border: white solid 0.5px
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    height: 6em;
    max-width: 100%;
    margin: 1px 0 !important;
    background: none;
    text-align: center;
    border: rgb(201, 201, 201) solid 1px !important;
    border-radius: 5px;
    color: #1f1f1f99;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;

  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now:enabled {
    background: #012063e6;
    color: white;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #1f3564e6;
  }
  .react-calendar__tile--hasActive {
    background: #0096D1;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #005f85;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #0085b9;;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  