.toptopNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
}

.linearGradientButton1 {
  background: linear-gradient(184.57deg, #0096d1 4.45%, #012063 81.91%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.linearGradientButton2 {
  background: linear-gradient(180deg, #012063 0%, #0096d1 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.messageBack {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #012063;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-left: 0.4em;
}

.messagePage {
  padding: 1em 2em;
}

.messageTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2em;
  gap: 2em 0;
}

.messageTop select {
  width: 200px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  /* identical to box height */

  color: rgba(31, 31, 31, 0.6);
}
.messageTop button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  background: linear-gradient(184.57deg, #0096d1 4.45%, #012063 81.91%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #ffffff;
  padding: 0 1em;
}
.messageSearch {
  width: 300px;
  height: 40px;
  padding: 0 0.4em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(31, 31, 31, 0.6);
  gap: 1em;
}
.messageSearchIcon {
  font-size: 20px;
}
.messageSearch input {
  height: 100%;
  width: 92%;
  border-radius: 20px;

  border: none;
  outline: none;
}

.messageBox {
  width: 100%;
  overflow-x: scroll;
}
.messageBox::-webkit-scrollbar {
  width: 0px;
}
/* Track */
.messageBox::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}

/* Handle */
.messageBox::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0;
}

/* Handle on hover */
.messageBox::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.messageBoxCon {
  min-width: 1400px;
  width: 100%;
}

.messageTabContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0em 1em;
  margin-bottom: 1em;
}
.online {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #25d366;
}
.offline {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 100%;
}

.messageInfo {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8em;
}
.messageInfo img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.messageInfoName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.messageInfoName h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #012063;
  margin: 0;
}

.messageInfoName span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1f1f1f80;
}
.lastMessge {
  width: 484px;
  height: 40px;
}

.lastMessge p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: rgba(1, 32, 99, 0.7);
}
.messsageTime {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.messsageTime h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: rgba(1, 32, 99, 0.7);
}
.messsageTime p {
  color: #1f1f1f80;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.chatTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 112px;
  background: linear-gradient(90.11deg, #012063 12.91%, #0096d1 59.28%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1em;
}

.chatProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.chatProfile img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.chatProfile h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}
.ChatDate {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.chatSystem {
  margin: 2em auto;
  height: calc(90vh - 200px);
  width: 100%;
  position: relative;
}

.firstMan {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  float: left;
}
.firstMan img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.firstChat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.4em;
}

.firstChat p {
  padding: 1em;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.firstChat span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: rgba(31, 31, 31, 0.5);
}
.secondMan {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  float: right;
  /* margin-top: 6em; */
}
.secondMan img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.secondChat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 0.4em;
}

.secondChat p {
  padding: 1em;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.secondChat span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  color: rgba(31, 31, 31, 0.5);
}

.chatInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  position: absolute;
  bottom: 0%;
  width: inherit;
}

.chatInputDiv {
  width: calc(100% - 130px);
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0 0.4em;
}

.chatInputDiv input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.inportDoc {
  width: 60px;
  height: 60px;
  background: #012063;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
}

.inportDoc input {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.chatIcon {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}

.broadcastReg {
  background: linear-gradient(180deg, #ffffff 0%, #f3fbfe 49.48%, #fcfeff 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 2em 0;
}

.broadcastForm {
  width: 900px;
  margin: 2em auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.broadcastForm h2 {
  width: 200px;
  text-align: right;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(1, 32, 99, 0.7);
}

.castFormp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
  width: calc(100% - 200px);
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.castFormp span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.8);
}
.castForm {
  width: calc(100% - 200px);
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.broadcastForm h2 p {
  color: rgba(1, 32, 99, 0.27);
}

.castForm select {
  height: 40px;
  width: 100%;
  background-color: transparent;
  outline: none;
}

.castForm input {
  height: 40px;
  width: 100%;
  background-color: transparent;
  outline: none;
}
.castForms {
  width: calc(100% - 200px);
  background: transparent;
}
.castForms input {
  height: 40px;
  width: 100%;
  background-color: transparent;
  outline: none;
}
.castForm textarea {
  height: 140px;
  padding: 0.5em;
  width: 100%;
  background-color: transparent;
  outline: none;
}

@media (max-width: 1400px) {
  .messagePage {
    padding: 1em 0.4em;
  }
  .messageTop select {
    /* width: 100%; */
  }
  .messageTop button {
    /* width: 100%; */
  }
  .messageTop a {
    /* width: 100%; */
  }
  .fromMedia {
    flex-direction: column;
    align-items: flex-start;
    width: 98%;
  }
  .fromMedia h2 {
    text-align: left;
  }
}

@media (max-width: 800px) {
  .castMedia {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-left: 1em;
  }
  .castMedia select {
    width: 100%;
  }
}
.mediaUpd {
  min-width: 700px;
}

@media (max-width: 1020px) {
  .mediaUpd {
    min-width: 98% !important;
  }
}

@media (max-width: 800px) {
  .mediaUpp {
    width: 100%;
    overflow-x: scroll;
  }

  .mediaUpd {
    min-width: 700px !important;
  }
}
