.studentReg {
  padding: 2em;
}
.mainReg {
  background: linear-gradient(180deg, #ffffff 0%, #f3fbfe 49.48%, #fcfeff 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 2em 0;
}
.regStudentForm {
  width: 900px;
  margin: 2em auto 2em 5em;
}
.regStudentForm h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #012063;
  text-align: center;
  margin-bottom: 1em;
}

.studentFormContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 2em;
  text-transform: capitalize;
}
.studentFormContent h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  width: 100px;
  min-width: 100px;
  text-align: right;
  margin-top: 5px;
  color: rgba(1, 32, 99, 0.7);
}
.nameForm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  flex-wrap: wrap;
}
.nameForm input {
  width: 340px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
}
.nameForm input::placeholder {
  text-transform: capitalize;
}
.studentFormHide {
  width: 340px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
  visibility: hidden;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
}
.genderForm {
  width: 191px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 1em;
}
.gen2 {
  width: 260px;
}
.genderForm span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.8);
  display: flex;
  align-items: center;
  gap: 0.4em;
}

.schInput {
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.reBtn {
  width: 289px;
  height: 64px;
  background: linear-gradient(180deg, #0096d1 0%, #012063 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.phoneOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.phoneOption button {
  width: 190px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: left;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  padding: 0 1em;
  color: rgba(31, 31, 31, 0.6);
}
.phoneOption input {
  width: 190px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;

  color: rgba(31, 31, 31, 0.6);
}
.educationFirst {
  width: 340px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
}

.educationdiv select {
  width: 70px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 0.3em;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
  margin-right: 0.5em;
  margin-top: 1em;
}
.educationdiv .educationSecond {
  width: 250px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
}

.studentRegBtn {
  width: 250px;
  height: 45px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: linear-gradient(180deg, #0096d1 0%, #012063 100%) !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 2em auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}
.addButton button {
  width: 328px;
  height: 40px;
  background: linear-gradient(
    180deg,
    rgba(0, 150, 209) 0%,
    rgba(1, 32, 99) 100%
  );
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.nameForms input {
  width: 399px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
  margin-bottom: 2em;
  outline: none;
}
.nameForms input::placeholder {
  text-transform: capitalize;
}
.nameFormDiv {
  display: flex;
  align-items: center;
  gap: 1em;
}
.nameFormDiv input {
  width: 190px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0 1em;
  outline: none;
}
.nameForms select {
  width: 399px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0 1em;
  outline: none;
}
.zip input {
  width: 190px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
}
.nameForm textarea {
  width: 399px;
  height: 102px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0.5em 1em;
}
.nameForm select {
  width: 214px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  padding: 0 1em;
  outline: none;
  color: rgba(31, 31, 31, 0.6);
}

.cancel {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fa614c 0%, #d7320d 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.canIcon {
  font-size: 20px;
  color: #fff;
}
.thirRed {
  width: 100%;
}
.imageOut {
  width: 100%;
}
.imageInput {
  width: 300px;
  height: 300px;
  margin: 4em auto 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.imageOutline {
  background: rgba(1, 32, 99, 0.7);
  border-radius: 9px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageInput input {
  position: absolute;
  width: inherit;
  height: 100%;
  opacity: 0;
}
.imageIcon {
  width: 44px;
  height: 44px;
  border-radius: 9px;
  border: 2px solid #fff;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.imageOut button {
  width: 289px;
  height: 64px;
  margin: 3em auto 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #0096d1 0%, #012063 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.class .studentFormContent select {
  height: 55px;
  width: 340px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.class button {
  width: 289px;
  height: 65px;
  background: linear-gradient(180deg, #0096d1 0%, #012063 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #ffffff;
}
.regStudentFormBac {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 1em;
}
.medForm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1em;
}

.medForm select {
  width: 300px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.medForm .genderForm {
  width: 300px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0;
}

.genderForm input {
  width: 15px;
  height: 15px;
  box-shadow: none;
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
  width: 100%;
  position: relative;
}
.firstInd {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #012063;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstInd2 {
  background: #ffffff;
  border: 1px solid rgba(31, 31, 31, 0.6);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  width: 60px;
  border-radius: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #012063;
}

@media (max-width: 1440px) {
  .regStudentForm {
    margin: 2em 0;
    width: 100%;
  }
}

@media (max-width: 1048px) {
  .studentFormHide {
    display: none;
  }

  .studentFormContent h4 {
    width: 150px;
  }
  .nameForm {
    width: calc(100% - 150px);
  }
  .nameForm input {
    width: 95%;
  }

  .nameForms {
    width: calc(100% - 150px);
  }
  .nameForms input {
    width: 95%;
  }
  .nameForms select {
    width: 95%;
  }
  .phoneOption {
    width: calc(100% - 150px);
  }
  .nameForms .nameFormDiv {
    width: 95%;
  }
  .schInput {
    width: calc(95% - 150px);
    flex-wrap: wrap;
    height: 100%;
  }
}

@media (max-width: 550px) {
  .studentReg {
    padding: 2em 0.5em;
  }
  .studentFormContent {
    flex-direction: column;
    padding-left: 0.4em;
  }
  .studentFormContent h4 {
    width: 100%;
    text-align: left;
  }
  .nameForm {
    width: 100%;
    margin: auto;
  }
  .nameForms {
    width: 100%;
    margin: auto;
  }
  .nameFormDiv {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .phoneOption {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .phoneOption button {
    width: 95%;
  }
  .phoneOption input {
    width: 95%;
  }
  .genderForm {
    width: 95%;
  }
  .gen2 {
    width: 95%;
  }
  .zip {
    width: 95%;
  }
  .zip input {
    width: 100%;
  }
  .schInput {
    width: 95%;
  }
  .nameForm select {
    width: 100%;
  }

  .addButton button {
    width: 100%;
    height: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
  }
  .nameForm textarea {
    width: 96%;
  }
}

@media (max-width: 425px) {
  .logBac {
    padding: 0 0.4em;
  }
  .imageInput {
    width: 98%;
  }
  .class .studentFormContent select {
    width: 98%;
  }
}
