@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Anton&family=Cinzel:wght@400;500;600;700;800;900&family=Dancing+Script&family=Poller+One&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.Login_bg_div {
  background-image: linear-gradient(#0096d1, #012063);
}
.Login_bg_div_h2 {
  border-bottom: 2px solid white;
  font-family: "Poppins";
  width: 140px;
}
.Login_bg_div_div_h4 {
  color: rgba(255, 255, 255, 0.8);
}
.Login_bg_div_div_p {
  color: rgba(255, 255, 255, 0.7);
}

.Login_page_button {
  background: linear-gradient(180deg, #0096d1 0%, #012063 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.Login_Input {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

/* ADMIN_NAV */
.AdminNav {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(208, 236, 247, 0.7) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* AdminDashboardCard */

.AdminDashboardCard_iconFill {
  background: rgba(168, 173, 185, 0.41);
  box-shadow: inset 0px -3.75104px 9.37759px rgba(255, 255, 255, 0.25);
}

/* AdminCalRec */
.AdminCalRec_main {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(208, 236, 247, 0.5) 100%
  );
}
.Raleway {
  font-family: "Raleway", sans-serif;
}

/* register scrooll  */

/*
 *  scroll bar style
 */

.register-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: none;
  border-radius: 10px;
  margin: 10px;
}

.register-scroll::-webkit-scrollbar {
  width: 5px;
  background-color: none;
}

.register-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #012063;
}

/* registerCard */
.registerCard {
  background: linear-gradient(108.69deg, #012063 -10%, #0096d1 68.53%);
}

/* attendance */

.attendance_bg {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(208, 236, 247, 0.7) 100%
  );
}
