
.landing {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #ffffff;
  overflow-x: hidden;
}

.landingNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  height: 75px;
}

.landingNav a img {
  width: 242px;
  height: 80px;
  object-fit: contain;
}

.navLists {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}
.landingheader {
  background: url("/public/10.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.headerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
  margin: 0em auto 4em;
  padding: 4em 0 0;
}


.landingHeaderText h2 {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 62px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
}
.landingHeaderText p {
  width: 618px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #f4dfdf;
}

.landingHeaderBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}

.headerBtn1 {
  width: 257px;
  height: 75px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  background: #0096d1;
  border-radius: 20px;
}
.headerBtn2 {
  width: 245px;
  height: 75px;
  border-radius: 20px;
  background: #e3f7ff;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #014c8c;
}

.landingHeaderPic {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1em;
}

.landingHeaderPic img {
  width: 395.95px;
  height: 100%;
  object-fit: contain;
}

.extraText {
  display: flex;
}

.extraText p {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;

  color: #9083aa;
}
.extraText span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #012063;
}


.landingAboutimg {
  width: 400px;
  height: 800px;
  object-fit: contain;
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
}

.landingAbout {
  position: relative;
  width: 100%;
  background: #05729d;
  display: flex;
  justify-content: center;
}

.landingAbout p {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
}

.landingAbout p span {
  font-weight: 600;
  font-size: 24px;
}

.landingHow {
  background: #f5f5f5;
  padding-top: 1em;
}

.landingHow h2 {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #05729d;
}
.howContent {
  max-width: 1440px;
  width: 100%;
}

.howContentCap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.howDoll {
  position: relative;
  width: 317.49px;
  height: 309.86px;
  background: #c4a8fe;
  border-radius: 100%;
}
.howDoll img {
  position: absolute;
  width: 342.75px;
  height: 253.46px;
  object-fit: contain;
  top: 15%;
  z-index: 1;
}

.howText {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.howText img {
  position: relative;
}

.howText p {
  position: absolute;
  width: 70%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "pnum" on, "lnum" on;
  font-size: 20px;
  background: linear-gradient(
    270.01deg,
    #0062a0 -18.36%,
    #015292 26.18%,
    #15212b 73.19%,
    #15212b 102.11%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.howDoll2 {
  position: relative;
  width: 317.49px;
  height: 309.86px;
  background: #f8a873;
  border-radius: 100%;
}
.howDoll2 img {
  position: absolute;
  width: 327px;
  height: 328px;
  object-fit: contain;
  z-index: 1;
}

.howText2 {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.howText2 img {
  position: relative;
  height: 100%;
}

.howText2 p {
  position: absolute;
  width: 70%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  font-feature-settings: "pnum" on, "lnum" on;

  background: linear-gradient(
    270.01deg,
    #0062a0 -18.36%,
    #015292 26.18%,
    #15212b 73.19%,
    #15212b 102.11%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.howDoll22 {
  position: relative;
  width: 317.49px;
  height: 309.86px;
  background: #55b9e0;
  border-radius: 100%;
}
.landingWho h2 {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #05729d;
  text-align: center;
}
.whoContent {
  max-width: 1440px;
  width: 100%;
  margin: 3em auto;
  padding: 20px;
}
.whoContentCap {
  margin-bottom: 2em;
}
.capContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.capText h2 {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: left;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #533e7e;
  margin-bottom: 0.5em;
}
.capText2 h2 {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: right;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #533e7e;
  margin-bottom: 0.5em;
}
.capText ul {
  padding: 0;
  margin: 0;
}
.capText ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #736197;
  list-style: disc;
  margin-bottom: 0.5em;
}

.capText2 ul {
  padding: 0;
  margin: 0;
}
.capText2 ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  line-height: 27px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #736197;
  list-style: disc;
  margin-bottom: 0.5em;
}
.capContainer img {
  width: 536px;
  height: 386px;
  object-fit: contain;
}
.whoContentCap p {
  margin: 1em 0;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;

  color: #05729d;
}

.whoContentCap button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  gap: 10px;
  height: 50px;
  background: #0096d1;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: auto;
}

.testimony {
  background: #fff8e4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.testimony p {
  font-family: "Poppins";
  font-style: normal;
  text-align: justify;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #888e9c;
  width: 70%;
  margin: 1em auto;
}
.tesIcon {
  font-size: 20px;
  color: #014e8e;
}

.landingDemo {
  height: 500px;
  position: relative;
  padding: 4em 0 0;
}
.demoCon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.demoCon img {
  width: 809.28px;
  height: 301px;
  margin: 1em auto;
}

.landingDemo .bac {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 300px;
}
.demoCon button {
  height: 50px;
  padding: 0 20px;
  background: #ffffff;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  margin: auto;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f1f1f;
}

.newsletter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0 15em;
  position: relative;
  width: 100%;
  margin: auto;
}

.newsText {
  height: 500.68px;
  width: 600.76px;
  background-position: center;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background: url(/public/14.svg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.newsletter button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 65px;
  background: #0096d1;
  border: 2px solid rgba(0, 100, 139, 0.5);
  border-radius: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
}

.newsletter .secondImg {
  width: 600px;
  height: 502px;
}

.newsletter .secondImg img {
  width: 100%;
  height: 100%;
}

.download {
  height: 320px;
  background: #05729d;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  margin: 5em 0 15em;
}

.downloadOut {
  position: absolute;
  height: 558px;
  width: 1200px;
  background: rgba(243, 250, 253, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}

.downloadIn {
  width: 90%;
  margin: auto;
  height: 90%;
  background: #ffffff;
  border: 1px solid #012063;
  border-radius: 20px;
  padding: 1em 0;
}

.downloadIn h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  /* identical to box height */
  text-align: center;
  color: #012063;
}

.downloadCont {
  margin: auto;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.downloadCont img {
  width: 376px;
  height: 407px;
  object-fit: contain;
}

.downloadContText h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #012063;
  margin-bottom: 0.5em;
}

.downloadContText ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: rgba(31, 31, 31, 0.8);
  list-style: disc;
}
.landingFooter {
  padding: 3em;
  background: #1a2028;
}
.footToop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footToopFiest {
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.footToopFiest img {
  width: 247px;
  height: 81px;
  object-fit: contain;
  margin-bottom: 1em;
}

.footToopFiest p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #94a3b8;
  margin-bottom: 0.5em;
}
.leftSu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #94a3b8;
  margin-bottom: 0.5em;
}
.footToop2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footToop2 ul h2 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #f8fafc;
  margin-bottom: 1em;
}
.footToop2 ul li {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1em;
  color: #94a3b8;
}

.footerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2em;
}
.footerBottom ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3em;
  flex-wrap: wrap;
}
.footerBottom ul li {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #94a3b8;
}
.footerBottom p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #94a3b8;
}

@media (max-width: 1440px) {
  .headerContainer,
  .howContent,
  .whoContent,
  .newsletter {
    max-width: 97%;
  }
}

@media (max-width: 1200px) {
  .landingHeaderPic {
    flex-direction: column;
  }
  .landingHeaderText h2 {
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 0.3em;
  }
  .landingHeaderText p {
    margin-bottom: 0.6em;
  }
  .headerBtn1 {
    width: 200px;
    height: 55px;
    font-size: 17px;
    line-height: 25px;
  }
  .headerBtn2 {
    width: 200px;
    height: 55px;
    font-size: 17px;
    line-height: 25px;
  }
  .extraText {
    width: 251px;
    margin-left: 0;
  }
  .landingAbout p {
    width: 600px;
    margin-left: auto;
  }

  .newsletter button {
    top: 85%;
  }

  .newsText {
    height: 400.68px;
    width: 500.76px;
  }
  .newsText {
    height: 400.68px;
    width: 500.76px;
  }
  .newsletter .secondImg {
    width: 500px;
    height: 402px;
  }
  .downloadOut {
    width: 97%;
  }

  .footToopFiest {
    width: 35%;
  }
}

@media (max-width: 1024px) {
  .headerContainer {
    flex-direction: column;
    margin: 0 auto;
    padding: 4em 0 0;
  }
  .extraText {
    display: none;
  }
  .landingHeaderPic {
    margin-left: auto;
  }
  .landingHeaderPic img {
    height: 487px;
  }
}

.landingHeaderBtn2 {
  display: none;
}
.landingAboutimg2 {
  display: none;
}
.howContentCap2 {
  display: none;
}

@media (max-width: 900px) {
  .navLists {
    display: none;
  }
  .landingHeaderText p {
    display: none;
  }
  .landingAboutimg {
    display: none;
  }
  .landingAbout p {
    width: 100%;

  }
  .landingAboutimg2 {
    display: block;
  }
  .howContentCap {
    flex-direction: column-reverse;
  }
  .howDoll {
    background: none;
  }
  .howContentCap {
    display: none;
  }

  .howContentCap2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .howDoll img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 0%;
    z-index: 1;
  }
  .howContentCap2 .howText img {
    height: 400px;
  }

  .howContentCap2 .howText p {
    width: 70%;
  }

  .capContainer {
    flex-direction: column;
  }
  .capText2 h2 {
    text-align: left;
  }

  .newsletter {
    padding: 1em 0 11em;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .download {
    margin: 5em 0 22em;
  }
  .downloadOut {
    height: 800px;
  }
  .downloadCont {
    flex-direction: column;
  }
  .footToop {
    flex-direction: column;
  }
  .footerBottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
  .landingFooter {
    padding: 3em 0.5em;
  }

  .footToopFiest {
    width: 97%;
  }
}

@media (max-width: 500px) {
  .landingHeaderBtn {
    display: none;
  }
  .landingHeaderBtn2 {
    display: flex;
    gap: 1em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .landingHeaderText h2 {
    font-size: 30px;
    line-height: 30px;
  }
  .landingAboutimg2 {
    width: 100%;
  }
  .landingHow h2 {
    font-size: 32px;
  }
  .howContentCap2 .howText {
    width: 98%;
  }
  .howContentCap2 .howText img {
    width: 100%;
  }
  .landingWho h2 {
    font-size: 32px;
  }
  .capText h2, .capText2 h2 {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .capText ul li, .capText2 ul li {
    font-size: 14px;
    line-height: 21px;
  }
  .whoContentCap p {
    display: none;
  }
  .whoContentCap button {
    display: none;
  }
  .testimony p {
    width: 98%;
  }
  .demoCon img {
    height: 200px;
  }
  .newsText {
    width: 98%;
    height: 300px;
    justify-content: flex-start;
    padding-top: 3em;
  }
  .newsletter .secondImg {
    width: 98%;
    height: 300px;
  }

  .downloadIn h2 {
    font-size: 18px;
    line-height: 27px;
  }
  .downloadCont img {
    width: 224px;
    height: 242px;
  }
  .downloadContText h3 {
    font-size: 15.0538px;
    line-height: 23px;
  }
  .downloadContText ul li {
    font-size: 8.02867px;
    line-height: 12px;
  }

  .footToopFiest img {
    width: 190px;
    height: 55px;
  }
  .footerBottom ul {
    gap: 1em;
  }
  .downloadOut {
    height: 500px;
  }
  .download {
    margin: 5em 0 5em;
  }
  .landingheader {
    background-size: 900px;
  }
}
