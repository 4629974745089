.studFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 3em;
  width: 100%;
  padding: 3em;
}
.stud {
  height: 176px;
  background: linear-gradient(108.69deg, #012063 1.33%, #0096d1 68.53%);
  border-radius: 20px;
  padding: 2em;
  position: relative;
  
}
.studentcircleOutline {
  top: 0;
  right: 0;
  position: absolute;
  width: 234.02px;
  height: 190.84px;
  background: rgba(168, 173, 185, 0.41);
  box-shadow: inset 0px -3.75104px 9.37759px rgba(255, 255, 255, 0.25);
  clip-path: circle(50% at 73% 35%);
  z-index: 0;
}
.Studentcircle {
  width: 223.53px;
  height: 180.47px;
  background: #0096d1;
  box-shadow: 0px 1.87552px 1.87552px rgba(77, 77, 77, 0.67);
  clip-path: circle(50% at 73% 35%);
  top: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

.stud h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.stud p {
  width: 130px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.8;
}
.iconbody {
  background: #012063;
  width: 36px;
  height: 36px;
  position: absolute;
  bottom: 20%;
  right: 10%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconbody p {
  border: 2px solid #fff;
  height: 25.5px;
  width: 25.5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 230px;
}
.studentData {
  background: linear-gradient(180deg, #ffffff 0%, #f3fbfe 49.48%, #fcfeff 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 1em 2em;
}
.studentDataTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
  flex-wrap: wrap;
  gap: 1em;
}

.studentDataTop select {
  width: 200px;
  height: 40px;
  outline: none;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.studentDataInputTop {
  width: 400px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  color: rgba(31, 31, 31, 0.6);
  padding: 0 1em;
}
.studentDataInputTop input {
  width: 90%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.studentDataTable {
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
}
.studentDataTable::-webkit-scrollbar {
  width: 0px;
}
/* Track */
.studentDataTable::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}

/* Handle */
.studentDataTable::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0;
}

/* Handle on hover */
.studentDataTable::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.studentDataTable table {
  width: 100%;
}
.studentDataTable table .tableHeads {
  height: 70px;
  background: #f4f7fe;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.studentDataTable table tr {
  text-align: left;
  height: 80px;
  background: #ffffff;

  padding-bottom: 3em;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.studentDataTable thead {
  height: 80px;
}
.studentDataTable table tr th:nth-child(1) {
  font-family: "Poppins";
  font-style: normal;
  font-size: 15px;
  line-height: 30px;
  color: rgba(1, 32, 99, 0.7);
  min-width: 150px;
}
.studentDataTable table tr th {
  /* min-width: max-content; */
  width: max-content;
  max-width: 240px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  color: rgba(31, 31, 31, 0.7);
}

.studentDataTable table tr td:nth-child(1) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  color: rgba(1, 32, 99, 0.8);
  min-width: 150px;
}
.studentDataTable table tr th:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  min-width: 150px;
}
.studentDataTable table tr td:nth-child(2) {
  font-family: "Poppins";
  font-style: normal;
  font-size: 14px;
  line-height: 27px;
  min-width: 150px;
}

.studentDataTable table tr td {
  min-width: max-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: rgb(36, 36, 36);
}
.studentDataTable table tr button {
  width: 107px;
  margin-top: 1em;
  height: 40px;
  background: #0096d1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  gap: 0.4em;
}
.upBtn button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  /* padding: 0 1em; */
  gap: 0.3em;
}

@media (max-width: 1440px) {
  .studFlex {
    gap: 3em 1em;
    padding: 3em 1em;
  }
  .stud {
    width: 350px;
  }
}

@media (max-width: 900px) {
  .link {
    width: 100%;
  }
  .stud {
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .stud {
    width: 97%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .circleOutline {
    width: 200.02px;
    height: 170.84px;
  }
  .Studentcircle {
    width: 183.53px;
    height: 160.47px;
  }
  .studFlex {
    padding: 3em 0.2em;
  }
}
