.report {
}
.reportHead {
  width: 100%;
  /* height: 50px; */
  padding: 10px;
  background: #00648b;
}
.reportHead h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #ecf0f9;
}

.reportHead p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ced8ed;
}

.reportContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  flex-wrap: wrap;
}
.reportDetai {
  margin-top: 1em;
  width: 309px;
  height: 107px;
  background: #f5f5f5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}

.reportDetai h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #004989;
}
.reportDetai p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #53555a;
}
.birthHead {
  margin-top: 1em;
}
.birthHead h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #012063;
}
.birthHead p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #877777;
}

.birthOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.birthOption select {
  width: 200px;
  height: 50px;
  background: #fffdfd;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  width: 200px;
  height: 50px;
  background: #fffdfd;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  width: 400px;
  height: 50px;
  background: #fffdfd;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.6);
}
.birthOption button {
  width: 262px;
  height: 50px;
  background: #0096d1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #f5f5f5;
}

.tableOverflow {
  width: 100%;
  overflow-x: scroll;
  display: flex;
}

.tableOverflow table {
  width: 100%;
  margin: auto;
}
.tableHead {
  height: 55px;
  background: #012063;
  border: 1px solid #ffffff;
  border-radius: 5px;
}
.tableOverflow table .tableHead th {
  min-width: 400px;
  width: 100%;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
}
.tableBody:nth-child(even) {
  height: 55px;
  background: #f1f2f2;
  border-radius: 5px;
  margin: 10px 0;
  border-radius: 5px;
}
.tableBody:nth-child(odd) {
  background: #f2f7f9;
  height: 55px;
  border-radius: 5px;
}

@media (max-width: 1440px) {
}

@media (max-width: 1200px) {
  .birthOption {
    gap: 2em 0;
  }
}

@media (max-width: 768px) {
  .reportContent {
    justify-content: center;
  }
  .tableOverflow table .tableHead th {
    min-width: 250px;
    width: 100%;
  }
    .birthOption {
    gap: 1em 0;
  }
  .birthHead h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #012063;
  }
  .birthOption select {
    width: 150px;
    height: 40px;
    background: #fffdfd;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(31, 31, 31, 0.6);
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    width: 150px;
    height: 40px;
    background: #fffdfd;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(31, 31, 31, 0.6);
  }
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 10px;
    width: 97%;
    height: 40px;
    background: #fffdfd;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin: auto;
    color: rgba(31, 31, 31, 0.6);
  }
  .birthOption button {
    width: 95%;
    height: 40px;
    background: #0096d1;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #f5f5f5;
}
}

@media (max-width: 500px) {
  .reportDetai {
    width: 95%;
  }
}
