.setting {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
}

.settingNav {
  width: 269px;
  height: 357px;
  background: #00648b;
  border-radius: 5px;
  padding: 1em 0 0;
}

.settingList {
  height: 50px;
  margin-bottom: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
}
.settingActive {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  height: 50px;
  margin-bottom: 1em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1em;
}

.schProfile {
  /* width: calc(95% - 269px); */
  width: 100%;
  background: #fafeff;
  box-shadow: 0px 0px 4px rgba(0, 150, 209, 0.5);
  border-radius: 5px;
  padding: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
}
.inputDv {
  height: initial;
  width: 632px;
}
.inputDv .input {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  margin-bottom: 1em;
  flex-direction: column;
}
.input textarea {
  width: 100%;
  height: 117px;
  background: #ffffff;
  border: 1px solid #9fb9c4;
  border-radius: 5px;
}
.inputDv .input1 {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  flex-direction: column;
  margin-bottom: 1em;
}
.schProfile label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #989090;
}

.schProfile input {
  height: 60px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #9fb9c4;
  border-radius: 5px;
}
.inputOpt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputOpt .input1 {
  width: 47%;
}
.camera {
  width: 228px;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.cameraInput {
  width: 228px;
  height: 228px;
  background: #d9d9d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cameraBtn {
}

.cameraBtn button {
  width: 269px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25d366;
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 1em;
}
.cameraBtn .btn2 {
  background: #f9fefb;
  border: 0.5px dashed #521e12;
  border-radius: 5px;
  outline: none;
  color: #494545;
}

.cameraBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cameraBtn1 button {
  width: 90%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #25d366;
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 1em;
}
.cameraBtn1 .btn2 {
  background: #f9fefb;
  border: 0.5px dashed #521e12;
  border-radius: 5px;
  outline: none;
  color: #494545;
}
.schProfile h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #00648b;
}
.faq {
  width: 992px;
  padding: .8em 0 .8em 0.5em;
  background: #ffffff;
  border: 1px solid #9fb9c4;
  border-radius: 5px;
  margin-bottom: 1em;
}
.faq h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #455860;
}
.faq p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #74858c;
}
