.activity {
  width: 130px;
  height: 100%;
  background: #f5f5f5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.5em;
}

.activityImg {
  width: 100%;
  height: 149px;
  margin: 1em auto;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activityImg img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.activity h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: rgba(1, 32, 99, 0.6);
  text-align: center;
}

.activities {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.activities select {
  width: 425px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0 1em;
  margin-bottom: 3em;
}

@media (max-width: 800px) {
  .activities select {
    margin-bottom: 0em;
  }
  .activitiesCap {
    max-height: 100% !important;
  }
}
.activitiesCap {
  padding: 0 2em;
  display: flex;
  gap: 3em;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.logActiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4em 0;
  flex-wrap: wrap;
  gap: 3em 0;
}

.logBac {
  padding: 0 2em;
}
.nameForma {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.nameForma input {
  padding: 0 1em;
  height: 40px;
  left: 567px;
  top: 243px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.nameForma select {
  width: 124px;
  height: 40px;
  padding: 0 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.logImg {
  width: 280px;
  height: 280px;
  object-fit: contain;
}
.picCap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  height: 40px;
  padding: 0 1em;
  background: #f5f5f5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: rgba(31, 31, 31, 0.6);
}
.picIcon {
  color: #012063;
  font-style: 14px;
}
.nameFormOp {
  width: 100%;
  padding: 0.4em 1em;
  height: 100%;
  background: #f5f5f5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  gap: 1em;
  align-items: center;
  flex-wrap: wrap;
}
.nameFormOp span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.8);
}
.nameFormOp span input {
  margin-right: 0.5em;
}

.activitiTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 2em;
}
.activitiTop select {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 200px;
  height: 40px;
  padding: 0 1em;
}
.activeBttn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 200px;
  height: 40px;
  padding: 0 1em;
}

.actfor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.actfor span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(1, 32, 99, 0.9);
}
.actfor p {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 0 1.5em;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: rgba(31, 31, 31, 0.6);
}

@media (max-width: 480px) {
  .activities select {
    width: 95%;
    height: 40px;
  }
  .activitiesCap {
    padding: 0 1em;
    gap: 3em 1em;
  }
  .logBac {
    padding: 0 0.4em;
  }
  .nameFormOp {
    width: 100%;
  }
  .mlo {
    margin-left: 1em;
  }
  .tess {
    flex-direction: column;
    align-items: flex-start;
  }
  .tess h4 {
    width: 100% !important;
    margin: 0;
  }
  .reMobile {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .act {
    margin: auto;
  }
  .activity {
    width: 200px;
    margin: auto;
  }
}
@media (max-width: 1024px) {
  .sleepTime {
    height: 100% !important;
  }
}
