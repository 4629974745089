.photoo {
  padding: 1em 2em;
}
.photoCardTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.photoCardTop button {
  height: 73px;
  background: linear-gradient(0deg, #0096d1, #0096d1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient();
  gap: 1em;
}

.photoCenter {
  height: 900px;
  background: linear-gradient(180deg, #ffffff 0%, #f3fbfe 49.48%, #fcfeff 100%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 2em 1em;
  position: relative;
}

.photoCenter select {
  padding: 1em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-right: 1em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.8);
}
.imageCollection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageCollection img {
  width: 202.5px;
  height: 200px;
}

.imageCollection p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: rgba(1, 32, 99, 0.8);
}

.headerBody {
  padding: 1em 2em;
  max-height: 91vh;
  overflow-y: auto;
}

.header {
  background: rgba(243, 250, 253, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 2em;
  position: relative;
}

.headerCircle {
  position: absolute;
  top: -4%;
  left: -2%;
  width: 402px;
  height: 402px;
  border-radius: 100%;
  background: rgba(0, 150, 209, 0.05);
}
.headerCap {
  background: #ffffff;
  border: 1px solid rgba(1, 32, 99, 0.5);
  border-radius: 20px;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerImg {
  width: 326px;
  height: 351px;
}

.headerImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.headerText {
  width: 728px;
}
.headerText h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 43px;
  line-height: 55px;
  background: linear-gradient(
    91.09deg,
    rgba(1, 32, 99, 0.9) 0.29%,
    rgba(0, 150, 209, 0.9) 44.96%,
    rgba(232, 195, 124, 0.9) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.headerText p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: rgba(31, 31, 31, 0.6);
  opacity: 0.8;
  width: 403px;
}
.headerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.headerBtn button {
  width: 119px;
  height: 50px;
  margin-top: 4em;
  cursor: pointer;
}
.bbnntt1 {
  background: linear-gradient(0deg, #0096d1, #0096d1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
}
.bbnnt {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid rgba(31, 31, 31, 0.3);
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}

.plans {
  display: flex;
  align-items: flex-start;
  justify-items: center;
  gap: 3em;
  flex-wrap: wrap;
  margin: 3em 0 0;
}
.plansCon {
  width: 391px;
  background: rgba(242, 250, 253, 0.29);
  border: 1px solid #e8e6e6;
  border-radius: 5px;
}
.plansConTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 59px;
  background: #0096d1;
  border-radius: 5px;
  padding: 0 1em;
}
.plansConTop h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}
.plansConTop p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  color: #ffffff;
}
.plansBody {
  padding: 1em;
}

.plansBody h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: rgba(31, 31, 31, 0.8);
}

.plansBody ul {
  margin: 0;
  padding: 0;
}

.plansBody ul li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: rgba(31, 31, 31, 0.4);
  opacity: 0.8;
  list-style: disc;
  margin-left: 2em;
  margin-bottom: 0.4em;
}

.plansBody button {
  width: 90%;
  height: 60px;
  background: #012063;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin-top: 2em;
}

.headerCapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid rgba(1, 32, 99, 0.5);
  border-radius: 20px;
  padding: 2em;
}
