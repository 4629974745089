.dashboard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.sideNav {
  width: 300px;
  height: 100%;
  background: #012063;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1em;
  position: fixed;
  min-height: 110vh;
  overflow-y: scroll;
}

.sideNav::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.sideNav::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}

/* Handle */
.sideNav::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0;
}

/* Handle on hover */
.sideNav::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

.toggleBtnns {
  display: none;
  position: absolute;
  top: 5%;
  left: 305%;
  z-index: 1111111111111;
}

.dASI {
  width: calc(98% - 300px);
  left: 300px;
  margin-left: auto;
}
.jiso {
  display: none;
}
@media (max-width: 805px) {
  .sideNav {
    width: 310px !important;
    height: 100%;
    background: transparent;
    box-shadow: none !important;
    padding: 1em;
    position: fixed !important;
    min-height: 110vh;
    left: -270px !important;
    z-index: 111111;
    transition: all 0.3s ease-in-out;
  }
  .activeNav {
    width: 300px;
    height: 100%;
    background: #012063;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1em;
    position: fixed !important;
    z-index: 1111111;
    left: 0;
    transition: all 0.3s ease-in-out;
  }
  .dASI {
    max-width: 89vw !important;
    width: 89vw !important;
    margin: auto;
  }

  .activeNav .listTop {
    justify-content: flex-start;
  }
  .sideNav .toggleBtnns {
    display: block;
    position: absolute;
    top: 3%;
    left: 89%;
    z-index: 111;
    color: #012063;
  }
  .activeNav .toggleBtnns {
    display: none;
    position: absolute;
    top: 4%;
    left: 105%;
    z-index: 111;
  }
  .sideNav .jio {
    display: none;
  }
  .jiso {
    display: block;
    color: #fff;
  }
  .activeNav .jio {
    display: block;
    position: absolute;
    top: 3%;
    left: 90%;
    z-index: 111;
  }
  .sideNav .toggleBtnnsIcon {
    font-size: 25px;
  }
  .nav {
    padding-top: 1em !important;
    padding-left: 2em !important;
  }
  .sks {
    margin-left: 1.6em !important;
  }
}
.logo {
  width: 80%;
  height: 40.42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo .laptopNav {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logo .mobileNav {
  display: none;
}
.listTop {
  margin: 2em 0;
}
.listTop li {
  list-style: none;
  margin-bottom: 0.5em;
  width: 88%;
}

.listTop li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  height: 50px;
  padding: 0 1em;
  transition: all 0.3s ease-in-out;
}
.listTop li a:hover {
  background: #ffffff;
  border-radius: 3px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #012063;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
}

.listBottom {
  margin-top: 3em;
  color: #fff;
}
.dashboardContent {
  width: calc(100% - 300px);
  padding: 0 1em 0 0;
  height: 100%;
  position: absolute;
  left: 300px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
  margin-bottom: 2em;
}
.nav p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  color: #012063;
  /* padding-bottom: 0.5em; */
  border-bottom: 2px solid rgba(0, 150, 209, 0.2);
}

.nav > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
}
.nav > div button {
  width: 171px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-left: 1em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #012063;
}
.navIcon {
  font-size: 20px;
}

.nav > div button img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.admin-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
  margin-bottom: 2em;
}

.admin-nav p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  color: #012063;
  /* padding-bottom: 0.5em; */
  border-bottom: 2px solid rgba(0, 150, 209, 0.2);
}

.admin-nav > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4em;
}
.admin-nav > div button {
  width: 171px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-left: 1em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #012063;
}
.navIcon {
  font-size: 20px;
}

.admin-nav > div button img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}


.dashBody {
  display: flex;
  width: 100%;
}

.dashFirst {
  width: 75%;
}

.dashCard {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  padding: 0 1em;
  margin-bottom: 3em;
}
.dashCardContent {
  width: 45%;
  height: 331px;
  background: rgba(243, 250, 253, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 0.5em;
  position: relative;
}

.circle {
  width: 200.53px;
  height: 150.47px;
  background: #0096d1;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0px 1.87552px 1.87552px rgba(77, 77, 77, 0.67);
  clip-path: circle(39.6% at 85% 19%);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.circleOutline {
  right: 10%;
  background-color: #fff;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 14%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleBlue {
  background: #012063;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.circleIcon {
  color: #fff;
  font-size: 15px;
}
.calendar {
  width: 25%;
  display: flex;
  flex-direction: column;
}
.chart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  margin-top: 4em;
}
.attend {
  margin-top: 9em;
}
.report {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
}
.report2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 7em;
}

.reportDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.reportDetails p {
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #0096d1;
  opacity: 0.8;
}
.absent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.absent p {
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #d7320d;
  opacity: 0.8;
}
.absent span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #d7320d;
}
.logOut {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
}
.logOut p {
  width: 35px;
  height: 35px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #d7320d;
  opacity: 0.8;
}
.logOut span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #d7320d;
}
.reportDetails span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: #012063;
}

.react-calendar {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: none !important;
  margin-bottom: 3em;
  width: 100% !important;
}

.message {
  width: 100%;
  height: 441px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 1em 0.5em;
}
.message button {
  width: 191px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  color: #012063;
}
.messageIcon {
  font-size: 20px;
}
.message p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0.5em;
  text-align: right;
  color: #012063;
  opacity: 0.8;
  margin-top: 1em;
}
.messaConntent {
  overflow-y: scroll;
  height: 90%;
}
.messaConntent {
  overflow-x: scroll;
}

.messaConntent::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.messaConntent::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 0;
}

/* Handle */
.messaConntent::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 0;
}

/* Handle on hover */
.messaConntent::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.messageContent {
  width: 100%;
  padding: 1em 0.4em;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
  margin-bottom: 2em;
}
.messageContent img {
  width: 50px;
  height: 50px;
  border: 1.5px solid #ffffff;
  border-radius: 100%;
}

.messageText h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 0em;
  color: #012063;
}
.messageText h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
  color: rgba(1, 32, 99, 0.8);
}
.messageTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.messageTime p {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: rgba(77, 77, 77, 0.67);
}

.messageTime span {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(77, 77, 77, 0.67);
}

@media (max-width: 1200px) {
  .dashboardContent {
    padding: 0;
  }

  .dashBody {
    flex-direction: column;
  }
  .dashFirst {
    width: 100%;
  }
  .calendar {
    width: 100%;
    padding: 0 1em;
    flex-direction: row;
    gap: 1em;
  }
  .react-calendar {
    height: 300px;
  }
}
@media (max-width: 1050px) {
  .dashCard {
    flex-direction: column;
  }
  .dashCardContent {
    width: 98%;
    margin: auto;
  }
}
@media (max-width: 900px) {
  .calendar {
    flex-direction: column;
  }
  /* .sideNav {
    width: 70px;
    padding: 2em 0;
  } */
  .dashboardContent {
    width: calc(100% - 70px);
    left: 70px;
  }

  .listTop li a {
    padding: 0;
    font-size: 17px;
  }

  .logo .laptopNav {
    display: block;
  }
  .logo .mobileNav {
    display: block;
    width: 30px;
  }
  .listTop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 700px) {
  .nav > div {
    display: none;
  }
  .chart {
    margin-top: 7em;
  }
  .listBottom {
    margin-top: 0;
  }
}

@media (max-width: 425px) {
  .dashCard {
    padding: 0 0.4em;
  }
  .dashCardContent {
    height: 100%;
    padding: 1em 0.3em;
  }
  .dashboardContent h2 {
    width: 100px;
  }
  .chart {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav p {
    font-size: 19px;
    line-height: 25px;
  }
}





    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .scroll-bar-in-dark::-webkit-scrollbar{
      width: 3px;
      background-color: #767676;
    }
    .scroll-bar-in-dark::-webkit-scrollbar-thumb { /* Inner part of a scroll bar */
      background-color: white;
      border-radius: 8px;
  }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
