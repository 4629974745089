/* @import url(https://fonts.googleapis.com/css?family=Roboto:regular);
body {
  font-family: "Roboto";
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.loaders {
  display: flex;
  flex-wrap: wrap;
  counter-reset: list;
}

.loaders div {
  position: relative;
  /* margin: 0 100px; */
}
.loaders div::before {
  /* counter-increment: list;
  content: counter(list, decimal-leading-zero); */
  position: absolute;
  top: -20px;
  left: -25px;
  font-size: 16px;
}

.loader1 span {
  display: block;
  width: 35px;
  height: 35px;
  border: 3px solid;
  border-radius: 50%;
  border-color: transparent #fff #fff;
  -webkit-animation: cssload-spin 690ms infinite linear;
          animation: cssload-spin 690ms infinite linear;
}
@-webkit-keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader2 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader2 span {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  -webkit-animation: loading2 1.2s linear infinite;
          animation: loading2 1.2s linear infinite;
}
.loader2 span:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.loader2 span:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.loader2 span:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.loader2 span:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.loader2 span:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.loader2 span:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.loader2 span:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.loader2 span:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.loader2 span:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.loader2 span:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.loader2 span:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.loader2 span:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@-webkit-keyframes loading2 {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
@keyframes loading2 {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}